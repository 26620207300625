import { SignService } from '../sign.service';
import axios from 'axios';
import authHeader from '../auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/wts/terms'

export class DictionaryService extends SignService {
  index() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  find(id) {
    return axios.get(`${API_URL}/${id}`, { headers: authHeader() })
  }
  save(term) {
    if (term.id) {
      return axios.patch(
        `${API_URL}/${term.id}`, { term }, { headers: authHeader() });
    }
    else {
      return axios.post(`${API_URL}`, { term }, { headers: authHeader() });
    }
  }
  addCategory(id, name) {
    console.log(id, name)
    return axios.post(
      `${API_URL}/${id}/categories`, { name }, { headers: authHeader() });
  }
  removeCategory(entryId, catId) {
    console.log(entryId, catId)
    return axios.delete(
      `${API_URL}/${entryId}/categories/${catId}`, { headers: authHeader() });
  }
  addTag(id, name) {
    console.log(id, name)
    return axios.post(
      `${API_URL}/${id}/tags`, { name }, { headers: authHeader() });
  }
  removeTag(entryId, tagId) {
    console.log({ entryId, tagId })
    return axios.delete(
      `${API_URL}/${entryId}/tags/${tagId}`, { headers: authHeader() });
  }
  deleteTerm(termId) {
    console.log({termId})
    return axios.delete(
      `${API_URL}/${termId}`, { headers: authHeader() }
    );
  }
}

export default new DictionaryService();
