<template>
  <unauthorized-access v-if="!isAuthorized">
    You are signed in as {{ currentUser.email }}.
  </unauthorized-access>
  <div
    v-else
    class="row"
  >
    <div class="col">
      <h3>Dictionary Editor</h3>
      <div class="d-grid gap2 mb-2">
        <button
          class="btn btn-secondary d-grid gap-2"
          @click="newSign"
        >
          Create New Term
        </button>
      </div>

      <Form
        :validation-schema="schema"
        @submit="findSigns(search)"
      >
        <div class="input-group">
          <Field
            id="search_input"
            v-model="search"
            name="search"
            type="text"
            class="form-control"
            autocomplete="off"
          />
          <button class="btn btn-primary">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Search
          </button>
        </div>
        <ErrorMessage
          name="search"
          class="error-feedback"
        />
      </Form>

      <div class="py-4">
        <ul class="list-group">
          <li
            v-for="entry in dictionary"
            :key="entry.id"
            class="list-group-item list-group-item-action"
            @click="showSign(entry.id)"
          >
            <!-- eslint-disable vue/no-v-html -->
            <a
              href="#"
              class="text-decoration-none text-reset"
              v-html="entry.title"
            />
            <!-- eslint-enable vue/no-v-html -->
          </li>
        </ul>
      </div>
    </div>
    <editor
      :id="entryId"
      ref="editor"
      @wts:term-created="termCreated"
      @wts:term-deleted="termDeleted"
    />
  </div>
</template>

<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from 'vee-validate';
import AdminAuthorizationCheck from "@/services/admin/authorization-check";
import DictionaryService from '@/services/admin/dictionary.service';
import Editor from './Editor';
import UnauthorizedAccess from '../UnauthorizedAccess.vue';
import { trackError } from "@/helpers/google-analytics-tracker";

export default {
  name: 'Dictionary',
  components: {
    'editor': Editor,
    Form,
    Field,
    ErrorMessage,
    UnauthorizedAccess,
  },
  setup() {
    return {
      ...AdminAuthorizationCheck()
    };
  },
  data() {
    const schema = yup.object().shape({
      search: yup.string(),
    });

    return {
      schema,
      search: "",
      dictionary: [],
      entryId: null,
      loading: false,
      error: null,
    }
  },
  async mounted() {
    const input = document.getElementById('search_input')
    input.focus();
    const query = this.$route.query;
    if (query?.q) {
      input.value = this.search = query.q;
      try {
        await this.findSigns(this.search);
      }
      catch(e) {
        trackError(e)
      }
    }
    else {
      try {
        await this.index();
      }
      catch(e) {
        trackError(e);
      }
    }
  },
  methods: {
    async index() {
      try {
        const resp = await DictionaryService.index();
        const { terms } = resp.data;
        this.dictionary = terms;
      }
      catch(e) {
        this.error = e.message;
        console.log(e.message);
        trackError(e);
      }
    },
    async findSigns(term) {
      this.loading = true;
      term = term.trim();
      this.$router.push({ path: '/admin/dictionary', query: { q: term }})
      if (term.length >= 1) {
        try {
          const response = await DictionaryService.search(term)
          console.log('response', response.data.terms)
          const { terms } = response.data;
          if (terms.length) {
            this.dictionary = terms;
            window.scrollTo(0, 0);
          }
        }
        catch (error) {
          console.log('Error:', error.message);
          trackError(error);
        }
        finally {
          this.loading = false;
        }
      }
    },
    showSign(id) {
      this.entryId = id;
      this.$refs.editor.show();
    },
    newSign() {
      this.entryId = null;
      this.$refs.editor.show();
    },
    termCreated(term) {
      this.entryId = term.id;
      this.dictionary.unshift(term);
    },
    termDeleted(termToRemove) {
      this.dictionary = this.dictionary.filter(term => term.id !== termToRemove.id);
    }
  },
}
</script>
